import { navigate } from 'gatsby'
import React, { BaseSyntheticEvent, useState } from 'react'

// Utils
import { createPath } from '../../../utils/utils'

// Models
import { ComicModel } from '../../../models'

// Icons
import NoCover from '../../../assets/icons/NoCover.png'

// Style
import {
  cardRole,
  cardInfo,
  cardName,
  cardExtra,
  cardCover,
  cardGenre,
  cardStatus,
  cardNoCover,
  cardWrapper,
  cardContainer,
} from './style.module.scss'

interface Props {
  comic: ComicModel
  role?: string
  exclude?: string
}

const ComicCard: React.FC<Props> = ({
  comic: { id, name, cover, status, genres },
  role,
  exclude,
}) => {
  const [coverStyle, setCoverStyle] = useState(cardCover)
  return (
    <div
      className={cardContainer}
      onClick={() => {
        navigate(`/comic/${id}-${createPath(name)}`)
      }}
    >
      {role && <div className={cardRole}>{role}</div>}
      <div className={cardWrapper}>
        <img
          className={coverStyle}
          src={`https://comiccolombiano.com/.img/covers/200x${
            cover?.split('.')[0]
          }.jpg`}
          onError={(e: BaseSyntheticEvent) => {
            e.target.src = NoCover
            setCoverStyle(cardNoCover)
          }}
          loading="lazy"
        />
        <div className={cardInfo}>
          <div className={cardName}>{name}</div>
          <div className={cardExtra}>
            <div className={cardGenre}>
              {exclude && genres.length > 1
                ? genres.find((g) => String(g.id) !== String(exclude))?.name
                : genres[0]?.name}
            </div>
            <div className={cardStatus} style={{ background: status.color }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComicCard
